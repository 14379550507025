import { timeFormat } from "d3-time-format";
import axios from "axios";
import { addDays } from "../../helpers";

export const formatDate = timeFormat("%b %d, %Y");

export const formatDateWithoutYear = timeFormat("%b %d");

export const riskSurfaceTypes = [
  "Risk",
  "Environment",
  "Abundance",
  "Infection",
  "Seroconversion",
  "Dead Bird",
];

export const speciesAgnosticRiskSurfaceTypes = {
  Environment: true,
  "Dead Bird": true,
  Seroconversion: true,
};

export const riskSurfaceTypeAbbreviations = {
  "Overall Risk": "Risk",
  Environment: "Env",
  Abundance: "Abund",
  Infection: "Inf",
  Seroconversion: "Sero",
  "Dead Bird": "Bird",
};

export const formatRiskSurfaceType = (riskSurfaceType) => {
  if (riskSurfaceType === "Risk") {
    return "Overall Risk";
  }
  if (riskSurfaceType === "Overall Risk") {
    return "Risk";
  }
  if (!riskSurfaceTypes.includes(riskSurfaceType)) {
    return "An invalid risk surface type was entered";
  }
  return riskSurfaceType;
};

export const calculateRiskColor = (riskBins, riskValue) => {
  if (riskValue === null) {
    return null;
  }
  let index = 0;
  let riskColor = "gray";
  let found = false;
  const roundedValue = Number(riskValue.toFixed(1)); // Use rounded as that is what the user sees

  while (index < riskBins.length && !found) {
    let item = riskBins[index];
    const { start, end, color } = item;

    riskColor = color;

    if (start <= roundedValue && roundedValue <= end) {
      found = true;
    }

    index += 1;
  }

  return riskColor;
};

const riskLegendTranslationsByState = {
  CA: {
    Normal: "Normal Season",
    Emergency: "Emergency Planning",
  },
};

export const generateRiskLegend = (riskBins, colorState) => {
  const translateTitles = riskLegendTranslationsByState[colorState] || {};
  const legendItems = [];
  for (let i = 0; i < riskBins.length; i += 1) {
    const { name, color } = riskBins[i];
    const newLegendItem = {
      strokeStyle: "solid",
      strokeWidth: 15,
      color,
      title: translateTitles[name] || name || `${i + 1} / ${riskBins.length}`,
    };
    legendItems.push(newLegendItem);
  }

  return legendItems;
};

export const formatDateForQuery = (year, month, day) => {
  let dateString = "";
  dateString += year;
  dateString += "-";
  dateString += month < 10 ? "0" + month : month;
  dateString += "-";
  dateString += day < 10 ? "0" + day : day;
  return dateString;
};

export const getInitialSaturday = (year) => {
  let firstSaturdayDate = 1;
  let count = 0;
  let targetDayValue = 6;
  while (
    count < 8 &&
    new Date(`${year}/01/${firstSaturdayDate}`).getDay() !== targetDayValue
  ) {
    firstSaturdayDate += 1;
    count += 1; // for emergencies
  }
  return firstSaturdayDate;
};

// be able to translate week number to the date it represents
export const numToWeek = (num, year, initialSaturday) => {
  const d = new Date(`${year}/01/${initialSaturday} 12:00:00 GMT-07:00`);
  d.setDate(d.getDate() + +num * 7, 1);
  return d;
};

// Only used if no week provided. Give the current week of the current year.
export const getCurrentWeek = () => {
  const year = new Date().getFullYear();
  const initialSaturday = getInitialSaturday(year);
  let d = new Date(`${year}/01/${initialSaturday} 12:00:00 GMT-07:00`);
  let countedWeeks = 0;
  const maxWeeksTotal = 54;
  while (d < Date.now() && countedWeeks <= maxWeeksTotal) {
    countedWeeks += 1;
    d.setDate(d.getDate() + 7, 1);
  }
  return countedWeeks;
};

// Returns the number of weeks in a given year, where a week begins on Saturday
export const getNumberOfWeeksInYear = (year, initialSaturday) => {
  let d = new Date(`${year}/01/${initialSaturday} 12:00:00 GMT-07:00`);
  let totalWeeks = 0;

  while (year === d.getFullYear()) {
    totalWeeks += 1;
    d.setDate(d.getDate() + 7, 1);
  }

  return totalWeeks;
};

// Gets the risk component values for a point. If the year's series has been fetched, should go to that, but not done yet.
export const getPointValues = async (
  authQueryString,
  longitude,
  latitude,
  year,
  initialSaturday,
  species,
  week
) => {
  const initialDate = numToWeek(week, year, initialSaturday);
  const stringDate = formatDateForQuery(
    initialDate.getFullYear(),
    initialDate.getMonth() + 1,
    initialDate.getDate()
  );

  try {
    const response = await axios.get(
      // `http://localhost:8011/v2/surfaces/livePointValues/${longitude}/${latitude}/${species}/${stringDate}/${authQueryString}`
      `https://mathew.vectorsurv.org/v2/surfaces/livePointValues/${longitude}/${latitude}/${species}/${stringDate}/${authQueryString}`
    );

    const data = response.data;

    return processFlattenedRisk(data);
  } catch (err) {
    console.error(err);
    return false;
  }
};

// Gets the series for a point for the year for the specified risk component. This would be more efficient when download is not an option.
export const getPointSeries = async (
  authQueryString,
  longitude,
  latitude,
  year,
  initialSaturday,
  species,
  week,
  riskSurfaceType
) => {
  const initialDate = numToWeek(week, year, initialSaturday);
  const stringDate = formatDateForQuery(
    initialDate.getFullYear(),
    initialDate.getMonth() + 1,
    initialDate.getDate()
  );

  try {
    const response = await axios.get(
      // `http://localhost:8011/v2/surfaces/livePointSeries/${longitude}/${latitude}/${species}/${stringDate}/${riskSurfaceType}${authQueryString}`
      `https://mathew.vectorsurv.org/v2/surfaces/livePointSeries/${longitude}/${latitude}/${species}/${stringDate}/${riskSurfaceType}${authQueryString}`
    );
    const data = response.data;

    processSeriesRisk(data);

    return data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// gets the entire years worth of data for all risk types, which is used for the downloads. The regulars should be used for mobile at some point, or when the user opts out of downloads.
export const getFullPointSeries = async (
  authQueryString,
  longitude,
  latitude,
  year
) => {
  try {
    const response = await axios.get(
      // `http://localhost:8011/v2/surfaces/livePointSeriesAll/${longitude}/${latitude}/${year}/${authQueryString}`//testSeries${authQueryString} // second one gets the cached series
      `https://mathew.vectorsurv.org/v2/surfaces/livePointSeriesAll/${longitude}/${latitude}/${year}/${authQueryString}` //testSeries${authQueryString} // second one gets the cached series
    );

    const data = response.data;

    return data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// Gets the risk component values for a polygon. If the year's series has been fetched, should go to that, but not done yet.
export const getPolygonValues = async (
  authQueryString,
  geoJSON,
  year,
  initialSaturday,
  species,
  week
) => {
  const initialDate = numToWeek(week, year, initialSaturday);
  const stringDate = formatDateForQuery(
    initialDate.getFullYear(),
    initialDate.getMonth() + 1,
    initialDate.getDate()
  );
  const stringoJSON = JSON.stringify(geoJSON);

  try {
    const response = await axios.get(
      // `http://localhost:8011/v2/surfaces/livePolygonValues/${stringoJSON}/${species}/${stringDate}/${authQueryString}`
      `https://mathew.vectorsurv.org/v2/surfaces/livePolygonValues/${stringoJSON}/${species}/${stringDate}/${authQueryString}`
    );

    const data = response.data;

    return processFlattenedRisk(data);
  } catch (err) {
    console.error(err);
    return false;
  }
};

// Gets the series for a polygon for the year for the specified risk component. This would be more efficient when download is not an option.
export const getPolygonSeries = async (
  authQueryString,
  geoJSON,
  year,
  initialSaturday,
  species,
  week,
  riskSurfaceType
) => {
  const initialDate = numToWeek(week, year, initialSaturday);
  const stringDate = formatDateForQuery(
    initialDate.getFullYear(),
    initialDate.getMonth() + 1,
    initialDate.getDate()
  );

  const stringoJSON = JSON.stringify(geoJSON);

  try {
    const response = await axios.get(
      // `http://localhost:8011/v2/surfaces/livePolygonSeries/${stringoJSON}/${species}/${stringDate}/${riskSurfaceType}${authQueryString}`
      `https://mathew.vectorsurv.org/v2/surfaces/livePolygonSeries/${stringoJSON}/${species}/${stringDate}/${riskSurfaceType}${authQueryString}`
    );
    const data = response.data;

    return data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// gets the entire years worth of data for all risk types, which is used for the downloads. The regulars should be used for mobile at some point, or when the user opts out of downloads.
export const getFullPolygonSeries = async (authQueryString, geoJSON, year) => {
  const stringoJSON = JSON.stringify(geoJSON);

  try {
    const response = await axios.get(
      // `http://localhost:8011/v2/surfaces/livePolygonSeriesAll/${stringoJSON}/${year}${authQueryString}` //testSeries${authQueryString} // second one gets the cached series
      `https://mathew.vectorsurv.org/v2/surfaces/livePolygonSeriesAll/${stringoJSON}/${year}${authQueryString}` //testSeries${authQueryString} // second one gets the cached series
    );
    const data = response.data;

    processSeriesRisk(data);

    return data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const findValueByDate = (arr = [], date) => {
  const riskObject = arr.find((item) => item?.date === date);
  return riskObject ? riskObject.value : null;
};

const processFlattenedRisk = (data) => {
  const formattedData = {};
  let numerator = 0;
  let denominator = 0;
  riskSurfaceTypes.forEach((riskSurfaceType) => {
    if (riskSurfaceType === "Risk") {
      // If risk should be the average, don't add it here, calculate it later
      return;
    }
    if (data[riskSurfaceType]) {
      const originalValue = data[riskSurfaceType];
      const formattedValue = roundDecimalPlace(data[riskSurfaceType]);
      formattedData[riskSurfaceType] = formattedValue;
      numerator += originalValue;
      denominator += 1;
    } else {
      formattedData[riskSurfaceType] = 0.0;
    }
  });

  if ((data["Risk"] && denominator) || (denominator > 1 && numerator)) {
    formattedData["Risk"] = roundDecimalPlace(numerator / denominator);
  } else {
    formattedData["Risk"] = 0;
  }

  return formattedData;
};

const processSeriesRisk = (data) => {
  Object.keys(data.species).forEach((species) => {
    data.species[species].Risk.forEach((riskObject) => {
      const date = riskObject.date;
      let numerator = 0;
      let denominator = 0;

      // infection and abundance are species specific
      ["Infection", "Abundance"].forEach((key) => {
        const value = findValueByDate(data.species[species][key], date);
        if (value !== null) {
          numerator += value;
          denominator += 1;
        }
      });

      // other factors are species agnostic
      Object.keys(speciesAgnosticRiskSurfaceTypes).forEach((type) => {
        const value = findValueByDate(data[type], date);
        if (value !== null) {
          numerator += value;
          denominator += 1;
        }
      });

      if (denominator > 0) {
        riskObject.value = numerator / denominator;
      } else {
        riskObject.value = 0;
      }
    });
  });
};

// Helper function to round a number to 1 decimal place
export const roundDecimalPlace = (val, decimalPlaces = 1) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(val * factor) / factor;
};

export const formatTimeSeries = (
  timeSeries,
  week,
  year,
  initialSaturday,
  riskFillColors,
  riskSurfaceType,
  species,
  isMobile = false
) => {
  const date = numToWeek(week, year, initialSaturday);
  date.setHours(0, 0, 0, 0);
  const timeSeriesChartData = [];
  let correctTimeSeries = [];

  if (speciesAgnosticRiskSurfaceTypes[riskSurfaceType]) {
    correctTimeSeries = timeSeries?.[year]?.[riskSurfaceType] || [];
  } else {
    correctTimeSeries =
      timeSeries?.[year]?.["species"]?.[species][riskSurfaceType] || [];
  }

  // we need to not include any points with null value, UNLESS they are the first or the last point
  const filteredTimeSeries = correctTimeSeries.filter(
    (point, index) =>
      index === 0 ||
      index === correctTimeSeries.length - 1 ||
      point.value != null
  );

  filteredTimeSeries.forEach((point, index) => {
    // each point has context, date, value
    const { value } = point;
    const seriesPoint = {};
    seriesPoint.x = addDays(new Date(point.date), 1); // server date likely diff than local date
    seriesPoint.x.setHours(0, 0, 0, 0);
    seriesPoint.y = value;
    if (date.getTime() === seriesPoint.x.getTime()) {
      seriesPoint.size = isMobile ? "4" : "8";
    } else {
      seriesPoint.size = isMobile ? "2" : "4";
    }
    if (value) {
      seriesPoint.fill = calculateRiskColor(riskFillColors, value);
    }
    timeSeriesChartData.push(seriesPoint);
  });

  if (timeSeriesChartData.length === 0) {
    // if there's nothing here, need an empty so the chart renders
    const emergencyPoint = {};
    emergencyPoint.x = addDays(date, 1);
    emergencyPoint.x.setHours(0, 0, 0, 0);
    emergencyPoint.y = null;
    emergencyPoint.size = "0"; // never used
    emergencyPoint.fill = "white"; // never used
    timeSeriesChartData.push(emergencyPoint);
  }

  timeSeriesChartData.sort((a, b) => a.x.getTime() - b.x.getTime());
  return timeSeriesChartData;
};
